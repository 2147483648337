import React from 'react';
import Logo from '../header/Logo';

import StickyButton from '../buttons/StickyButton';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import API from '../../config/apis/APIs';

const PracticePolicies = ({ match }) => {
  const {
    params: { customerid },
  } = match;
  let history = useHistory();

  function handlePracticePolicy() {
    axios
      .patch(`${API}/billing-information/${customerid}`, {
        practicePolicy: true,
      })
      .then(function () {
        history.push(`/${customerid}/thank-you/`);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  return (
    <>
      <Logo />
      <PracticePoliciesContent />
      <StickyButton action={handlePracticePolicy} />
    </>
  );
};

const PracticePoliciesContent = () => {
  return (
    <div className="pt-12 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-28 lg:px-8 max-w-4xl mx-auto">
      <h1 className="text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-3xl sm:leading-none md:text-4xl lg:text-4xl mb-6">
        Practice Policies
      </h1>
      <div className="max-w-4xl mx-auto">
        <section>
          <h2 className=" text-xl mb-10 md:mb-16">Attendance and Cancellation Policy</h2>
          <h3 className="font-semibold mt-10">Attendance Policy</h3>
          <p className="mt-4">
            You are expected to attend 80% of your scheduled appointments to maintain a recurring appointment slot with
            your therapist. Late cancellations, missed appointments, and appointments cancelled more than 24 hours in
            advance that are not rescheduled are considered unattended appointments in this calculation.
          </p>
          <p className="mt-4">
            If you are a parent or caregiver of a minor child Family Member, you are encouraged to attend all
            appointments when possible. If your minor child Family Member is under the age of 7, you or another
            authorized adult caregiver must be present for all appointments. If your minor child Family member is 7
            years of age or older, you may be required to be present for all appointments if deemed necessary based on
            the clinical judgement of your speech therapist.
          </p>
          <h3 className="font-semibold mt-10">Cancellations </h3>
          <p className="mt-4">
            If you were unable to attend a session due to an extenuating circumstance, please email us
            at info@expressable.io or call 512-399-0064.
          </p>

          <h3 className="font-semibold mt-10">Late Cancellations</h3>
          <p className="mt-4">
            You may cancel an appointment with no charge. If you used an appointment credit to book the appointment, the credit will be returned to your account.
          </p>

          <h3 className="font-semibold mt-10">Missed Appointments</h3>
          <p className="mt-4">
            If you miss an appointment without cancelling, a missed appointment fee of $25 may be charged to your
            Payment Method. If you used an appointment credit to book the appointment, the credit will be returned to
            your account.
          </p>

          <h3 className="font-semibold mt-10">Therapist Cancellations</h3>
          <p className="mt-4">
            If a session is cancelled because your therapist is unable to attend on the scheduled time, we will attempt
            to reschedule that session at a time of your convenience. You will not be charged if your therapist cancels
            your appointment for any reason and the appointment cannot be rescheduled.
          </p>
          <p className="mt-4">
            Late cancellation and missed appointment fees will not be charged when prohibited by law.
          </p>
        </section>

        <section>
          <p className="mt-12 font-semibold">
            BY CLICKING ON THE AGREE BUTTON BELOW I AM AGREEING THAT I HAVE READ, UNDERSTOOD AND AGREE TO THE ITEMS
            CONTAINED IN THIS DOCUMENT.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PracticePolicies;
