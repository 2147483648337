import React, { useEffect } from 'react';
import Logo from '../header/Logo';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import API from '../../config/apis/APIs';
import axios from 'axios';

function ThankYou({ match }) {
  const {
    params: { customerid },
  } = match;

  const { status, data, error } = useQuery(
    'apiBilling',
    () => axios.get(`${API}/billing-information/${customerid}`).then(res => res.data.body),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'production' && window.gtag) {
      window.gtag('event', 'purchase', {
        send_to: 'AW-708764555/Eh2TCImd8PoBEIvH-9EC',
      });
      window.gtag('event', 'purchase', { send_to: 'G-N6KPLMXS00' });
    }
  }, []);

  if (status === 'error') {
    // TODO: Better design for this
    return <div>Error: {error.message}</div>;
  }

  if (status === 'success') {
    return (
      <>
        <Logo />
        <ThankYouContent evaluationDate={data?.Item?.evaluationDate} status={status} />
      </>
    );
  }

  return (
    <>
      <Logo />
      <ThankYouContent />
    </>
  );
}

export default ThankYou;

// ----------- Content ------------

function ThankYouContent({ evaluationDate, status }) {
  const evaluationDateContent =
    typeof evaluationDate != 'undefined'
      ? ` on ${dayjs(evaluationDate.split('T')[0]).format('MMMM D').toString()}`
      : null;

  return (
    <div className="pt-12 pb-20 px-6 sm:px-6 lg:pt-12 lg:pb-28 max-w-4xl mx-auto">
      <h1 className="text-3xl mb-16 tracking-tight leading-10 font-extrabold text-gray-900 sm:text-3xl sm:leading-none md:text-4xl lg:text-4xl">
        {status === 'success' ? 'Thank you for completing your intake!' : null}
      </h1>

      <div className="max-w-4xl mx-auto">
        <section>
          <p className="font-medium text-2xl">
            We look forward to seeing you<span className="font-semibold">{evaluationDateContent}</span>!
          </p>
          <p className="mt-4">
            Before your first session, please review the following information. And of course, if you have questions,
            you can always text your therapist, call Expressable at (512) 399-0064, or email us at info@expressable.io
          </p>
        </section>

        <section>
          <h2 className="mt-12 text-xl">How do the video calls work?</h2>
          <p className="mt-4">
            We use Zoom for our video conferencing calls. You can download the app ahead of time{' '}
            <a className="text-blue-700 underline" href="https://zoom.us/download">
              here
            </a>
            . If you have any issues accessing Zoom, please refer to this{' '}
            <a
              className="text-blue-700 underline"
              href="https://support.zoom.us/hc/en-us/sections/200305593-Troubleshooting"
            >
              list of tips and tricks
            </a>
            .
          </p>
        </section>

        <section>
          <h2 className="mt-12 font-medium text-xl">How does billing work? </h2>
          <p className="mt-4">
            Expressable charges our clients on a subscription basis. We will automatically bill you either weekly or
            bi-weekly depending on the frequency of sessions you schedule with your therapist.{' '}
          </p>
        </section>

        <section>
          <h2 className="mt-12 font-medium text-xl">What happens if I need to cancel a session?</h2>
          <p className="mt-4">
            We understand that life happens, and sometimes you need to cancel a session. No problem! We simply ask that
            you notify your therapist as soon as possible, at least 24 hours in advance. Expressable will only charge
            you once your session is attended.
          </p>
          <p className="mt-4">
            If you miss a session altogether without notifying your therapist, you will be charged a no-show ($25) fee. This is to ensure that we can
            compensate your therapist, who will have reserved time on the calendar.
          </p>
        </section>
      </div>
    </div>
  );
}
